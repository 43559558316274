$(window).on('load',function(){
    $('body').addClass('is-loaded');
});

$(window).on('beforeunload',function(e){
    $('body').removeClass('is-loaded');
});

$('.js-toggleStateMenu').on('click',function(){

    if ( !$('body').hasClass('is-state--menu') ) {
        $('body').addClass('is-state--menu');
        if ( !$('.js-player').hasClass('is-playing') ) {
            $('.js-player').addClass('is-playing');
            $('.js-video').get(0).play();
        }
    } else {
        $('body').removeClass('is-state--menu');
        if ( !$('body').hasClass('is-page--home') ) {
            $('.js-player').removeClass('is-playing');
            $('.js-video').get(0).pause();
        }
    }
});

$('.js-workLink').on('mouseenter',function(){
    $('body').addClass('is-state--loop');
    $(this).addClass('is-hovering');
    var loop = $(this).attr('data-link');
    if ( $('.js-workLoop[data-loop="'+loop+'"]').length ) {
        $('.js-workLoop[data-loop="'+loop+'"]').addClass('is-visible');
        $('.js-workLoop[data-loop="'+loop+'"]').get(0).play();
    }
});

$('.js-workLink').on('mouseleave',function(){
    $('body').removeClass('is-state--loop');
    $(this).removeClass('is-hovering');
    var loop = $(this).attr('data-loop');
    if ( $('.js-workLoop.is-visible').length ) {
        $('.js-workLoop.is-visible').get(0).pause();
        $('.js-workLoop.is-visible').removeClass('is-visible');
    }
});

var player = document.querySelector(".js-player");

if ( player ) {

    var play_btn = document.querySelector(".js-play"),
        sound_btn = document.querySelector(".js-sound"),
        video = document.querySelector(".js-video");

    play_btn.addEventListener('click', function(el) {
        if ( !video.paused ) {
            video.pause();
            player.classList.remove('is-playing');
        } else {
            video.play();
            player.classList.add('is-playing');
        }
    });

    sound_btn.addEventListener('click', function(el) {
        if( !video.muted ){
            video.muted = true;
            player.classList.add('is-muted');
        } else {
            video.muted = false;
            player.classList.remove('is-muted');
        }
    });
}

function checkHeaderPositions( item ) {

    var logo = $('.js-logo'),
        logoBox = logo[0].getBoundingClientRect(),
        logoBoxBottom = Math.ceil(logoBox.bottom),
        logoBoxRight = Math.ceil(logoBox.right);

    logo.removeClass('is-positive');

    var menu = $('.js-toggleStateMenu'),
        menuBox = menu[0].getBoundingClientRect(),
        menuBoxBottom = Math.ceil(menuBox.bottom),
        menuBoxLeft = Math.ceil(menuBox.left);

    menu.removeClass('is-positive');

    if ( item ) {

        var hoveredBox = item.getBoundingClientRect();

        if ( hoveredBox.top > logoBoxBottom ) {
            logo.addClass('is-positive');
        } else {
            if ( logoBoxRight < hoveredBox.left ) {
                logo.addClass('is-positive');
            }
        }

        if ( hoveredBox.top > menuBoxBottom ) {
            menu.addClass('is-positive');
        } else {
            if ( menuBoxLeft > hoveredBox.right ) {
                menu.addClass('is-positive');
            }
        }

    }

}

if ( $('.js-items').length ) {

    $('.js-item').on('mouseenter', function(e){

        var item = this;

        checkHeaderPositions(item);

    });

    $('.js-item').on('mouseleave', function(){

        checkHeaderPositions();

    });

}

if ( $('.js-infScrollNextPage').length ) {

    $('.js-infScrollContainer').infiniteScroll({
        path: '.js-infScrollNextPage',
        append: '.js-infScrollItem',
        history: false,
        elementScroll: true,
        scrollThreshold: ($(window).height()*1.5)
    });

    // $('.js-infScrollContainer').on( 'load.infiniteScroll', function( event, response, path, items ) {
    //     console.log('fetching');
    // });

    $('.js-infScrollContainer').on( 'append.infiniteScroll', function( event, response, path, items ) {
        $('.js-infScrollNextPage').remove();
        $(items).each(function() {
            var $video = $(this).find("video");
            if ( $video ) {
                $source = $video.find('source');
                $source.attr('src', $source.attr('data-src'));
                $source.removeAttr('data-src');
                $video.attr('muted', true);
                $video.attr('playsinline', true);
                $video.attr('loop', true);
                $video.attr('autoplay', true);
                $video[0].load();
            }
        })
    });

}
